import React, { useState } from "react";

import alternate from "../../assets/home/noel_couv.png";

const Noel = () => {
	const [bookInfo, setBookInfo] = useState(false);
	const [toClose, setToClose] = useState(false)

	const handleBookDisplay = () => {
		setToClose(false)
		setBookInfo(true);
	};

	const closeBookInfo = () => {
		setToClose(true)
		setTimeout(() => {
			setBookInfo(false)
		}, 2000);
	}

	return (
		<>
			<section className="book-info" id="books">
				<div className="book-info__content">
					<div className="book-info__content--text">
						<h2>Un Noël 2.0</h2>
						<p>« Une histoire à la fois drôle et émouvante, entre amitié et amour, sur fond de sapin et de chocolat chaud à la guimauve. »</p>
						<button className="btn btn-primary" onClick={handleBookDisplay}>
							Découvrir
						</button>
					</div>

					<div className="book-info__content--picture">
						<img src={alternate} alt="new book" />
					</div>
				</div>
			</section>
			{bookInfo && 
				<section className={toClose ? "books-info-section-to-close books-info-section noel" : "books-info-section noel"} id="booksInfo">
					<button onClick={closeBookInfo} className="btn btn-close">← Retour</button>
					<div className={toClose ? "books-info-section__support-to-close books-info-section__support" : "books-info-section__support"}></div>
					<div className="books-info-section__content">
						<section className="up">

						<div className="books-info-section__content--info">
								<div className="information">
									<h5>Catégories</h5>
									<p>Noël, Romance, Fantastique</p>
									<br/>
									<h5>Date de parution</h5>
									<p>18 novembre 2022</p>
									<br/>
									<h5>Broché</h5>
									<p>208 pages, Français</p>
								</div>
								<div className="buy">
									<p>
									« Une histoire à la fois drôle et émouvante, entre amitié et amour, sur fond de sapin et de chocolat chaud à la guimauve. »
									</p>
									<button className="btn btn-noel" onClick={handleBookDisplay}>
										<a b href="https://www.amazon.fr/dp/B0BLSXHKVK" target="blank">Acheter</a>
									</button>
								</div>
							</div>

							<div className="books-info-section__content--resume">
								<h4>Résumé</h4>
								<p>
                                    Londres, 1890. De la magie, il en reste. Lady Elise en est gardienne depuis son plus jeune âge.
									<br/><br/>
									Lorsqu’elle est contrainte par ses parents, le Duc et la Duchesse d’Edgerton, à un mariage forcé avec Sir Albert de Noringhton, elle se retrouve à franchir un portail magique pour y échapper. Lady Elise, envoyée en 2021, n’a d’autres choix que d’apprendre à vivre dans cette nouvelle époque dont elle ne connait rien.
									<br/><br/>
									Heureusement pour elle, son chemin va croiser celui de Tara et de Thomas. Pour le meilleur, comme pour le pire…
								</p>
							</div>
							
						</section>
					</div>
				</section>
			}
		</>
	);
};

export default Noel;
