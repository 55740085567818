import React from "react";
import NavBar from "./components/NavBar";

import Home from "./components/sections/Home";
import Author from "./components/sections/Author";
import Books from "./components/sections/Books";
import Services from "./components/sections/Services";
import Contact from "./components/sections/Contact";

const App = () => {
	return (
		<div className="App">
			<NavBar />

			<Home />
			<Author />
			<Books />
			<Services />
			<Contact />
		</div>
	);
};

export default App;
