import React from "react";

import profilPic from "../../assets/author/profil-pic.jpg";

const Author = () => {
	return (
		<section className="author-section" id="author">
			<div className="author-section__content">
				<div className="author-section__content--picture">
					<img src={profilPic} alt="profil pic" />
				</div>
				<div className="author-section__content--text">
					<h1>Hello,</h1>
					<p>Je m&#39;appelle Elodie Pascal, j&#39;écris depuis plus de cinq ans et j’ai publié mon premier roman en
					autoédition en octobre 2022 : <a href="#books"><strong>Brisée</strong></a></p>
					<p>En parallèle, je travaille sur mes prochains projets d&#39;écriture. J’écris principalement dans les
					genres suivants : contemporain et fantastique.</p>
					<p>A très vite pour la suite !</p>
					{/* <a href="#books" className="btn btn-primary">
						Lorem
					</a> */}
				</div>
			</div>
		</section>
	);
};

export default Author;
