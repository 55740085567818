import React, { useState } from "react";

import alternate from "../../assets/home/alternate.png";
// import starRegular from "../../assets/ico/star-regular.svg"
// import starSolid from "../../assets/ico/star-solid.svg"
// import starHalfStroke from "../../assets/ico/star-half-stroke-regular.svg"

const Brisee = () => {
	const [bookInfo, setBookInfo] = useState(false);
	const [toClose, setToClose] = useState(false)

	const handleBookDisplay = () => {
		setToClose(false)
		setBookInfo(true);
	};

	const closeBookInfo = () => {
		setToClose(true)
		setTimeout(() => {
			setBookInfo(false)
		}, 2000);
	}

	return (
		<>
			<section className="book-info" id="books">
				<div className="book-info__content">
					<div className="book-info__content--text">
						<h2>Brisée</h2>
						<p>« Le temps ne dure jamais. Le bonheur et l’amour non plus. Malgré tous les efforts que nous déployions, au fond,
						nous savions qu’une fleur germe, éclot, et meurt. »</p>
						<button className="btn btn-primary" onClick={handleBookDisplay}>
                            Découvrir
						</button>
					</div>

					<div className="book-info__content--picture">
						<img src={alternate} alt="new book" />
					</div>
				</div>
			</section>
			{bookInfo && 
				<section className={toClose ? "books-info-section-to-close books-info-section brisee" : "books-info-section brisee"} id="booksInfo">
					<button onClick={closeBookInfo} className="btn btn-close">← Retour</button>
					<div className={toClose ? "books-info-section__support-to-close books-info-section__support" : "books-info-section__support"}></div>
					<div className="books-info-section__content">
						<section className="up">

						<div className="books-info-section__content--info">
								<div className="information">
									<h5>Catégories</h5>
									<p>Contemporain, Drame, Littérature Française, Romance</p>
									<br/>
									<h5>Date de parution</h5>
									<p>14 octobre 2022</p>
									<br/>
									<h5>Broché</h5>
									<p>200 pages, Français</p>
								</div>
								<div className="buy">
									<p>
									« Comment réagiriez-vous si la personne avec qui vous vivez n'est pas celle que vous pensiez ?
										Plongez au cœur d'une histoire entre amour, amitié et faux semblants. »
									</p>
									<button className="btn btn-brisee" onClick={handleBookDisplay}>
										<a b href="https://www.amazon.fr/dp/B0BHX5FJ26" target="blank">Acheter</a>
									</button>
								</div>
							</div>

							<div className="books-info-section__content--resume">
								<h4>Résumé</h4>
								<p>
									Emilie rencontre Jules pour la première fois à l&#39;arrêt du bus 89, près des Jardins du Luxembourg.
									Une relation naît.
									<br/><br/>
									Mais dès les débuts Jules dévoile une facette sombre, effrayante.
									<br/><br/>
									Jusqu&#39;où Jules sera t-il prêt à aller pour garder Emilie auprès de lui ?
									Emilie ne tarde pas à se rendre compte, que l&#39;homme avec qui elle a choisi de faire sa vie, est bien
									plus possessif qu&#39;elle ne l&#39;imagine…
								</p>
							</div>
							
						</section>
						{/* <section className="down">
							<div className="critic-card">
								<h5>LaBibliodeMogo</h5>
								<div className="rating-star">
									<img src={starSolid} alt="star" />
									<img src={starSolid} alt="star" />
									<img src={starSolid} alt="star" />
									<img src={starSolid} alt="star" />
									<img src={starHalfStroke} alt="star" />
									<p>Pépite livresque</p>
								</div>
								<p>
								" Un seul mauvais coup de pinceau, et l’œuvre serait ratée.
								</p>
								<br />
								<p>
									Un roman qui porte très bien son nom. Une œuvre bouleversante, avec des moments de joies, de doutes et de peines. 
								</p>
								<br/>
								<p>
								C’est un roman court, qui se lit d’une traite tellement c’est intense. Les émotions sont décuplées à chaque page. "
								</p>
							</div>

							<div className="critic-card">
								<h5>Belliard Samantha</h5>
								<div className="rating-star">
									<img src={starSolid} alt="star" />
									<img src={starSolid} alt="star" />
									<img src={starSolid} alt="star" />
									<img src={starSolid} alt="star" />
									<img src={starRegular} alt="star" />
									<p>Une plume addictive</p>
								</div>
								<p>
								" La plume de l'autrice est très agréable à lire, il y a également un côté très addictif, j'ai clairement lu cette histoire en moins de 24h tellement j'ai été prise dans ma lecture !
								</p>
								<br />
								<p>
								Vous l'aurez compris, c'est un roman que j'ai beaucoup apprécié ! "
								</p>
								<br/>
							</div>

							<div className="critic-card">
								<h5>Tristana</h5>
								<div className="rating-star">
									<img src={starSolid} alt="star" />
									<img src={starSolid} alt="star" />
									<img src={starSolid} alt="star" />
									<img src={starSolid} alt="star" />
									<img src={starSolid} alt="star" />
									<p>Une histoire émouvante</p>
								</div>
								<p >
								" Il y a des rencontres qui changent une vie. Malheureusement, ce n'est pas toujours en bien et il faut faire avec.
								</p>
								<br />
								<p>
								Une histoire qui nous tient entre ses griffes dès les premières lignes et jusqu'aux dernières. Les personnages sont attachants. J'ai beaucoup aimé découvrir l'histoire. "
								</p>
							</div> 

							
						</section> */}
					</div>
				</section>
			}
		</>
	);
};

export default Brisee;
