import React, { useEffect, useRef, useState } from "react";
import emailjs from "@emailjs/browser";

const ContactForm = () => {
	useEffect(() => {
		const inputs = document.querySelectorAll("input, textarea");
		for (let i = 0; i < inputs.length; i++) {
			inputs[i].style.animationDelay = `${1800 + 200 * i}ms`;
		}
	});
	// Input states
	const [name, setName] = useState("");
	const [compagny, setCompagny] = useState("");
	const [mail, setMail] = useState("");
	const [message, setMessage] = useState("");
	// const [messageSent, setMessageSent] = useState(false);

	// Error states
	const [nameError, setNameError] = useState(false);
	const [mailError, setMailError] = useState(false);
	const [messageError, setMessageError] = useState(false);

	const form = useRef();

	// Regex
	function inputValidation() {
		if (name.length < 24 && name.length >= 3) {
			setNameError(false);
			if (compagny.length < 24) {
				if (mail.length < 52 && (mail.length > 6)) {
					setMailError(false);
					if (message.length < 300 && message.length > 12) {
						setMessageError(false);
						return true;
					} else {
						setMessageError(true);
						return false;
					}
				} else {
					setMailError(true);
					return false;
				}
			} else {
				return false;
			}
		} else {
			setNameError(true);
			return false;
		}
	}

	// Handle submit
	const sendEmail = (e) => {
		e.preventDefault();
		if (inputValidation()) {
			emailjs.sendForm("service_7011l3n", "template_99uf938", form.current, "user_SkGwgFyb1mityhbwFYyle").then(
				(res) => {
					console.log("Message sent, thank u :)");
					setName("");
					setCompagny("");
					setMail("");
					setMessage("");
				},
				(err) => {
					console.log(err.text);
				}
			);
		} else {
			console.log("error");
		}
	};

	return (
		<div className="contact-section__content--right-part">
        <h2>Contact</h2>
			<form ref={form} onSubmit={sendEmail}>
				<div className="form">
					<div>
						<label htmlFor="name">
							<input placeholder="Nom *" name="name" type="text" minLength="3" maxLength="24"  value={name} onChange={(e) => setName(e.target.value)} required />
							{nameError && <div className="error">Nom invalide.</div>}
						</label>
						<br />
						<label htmlFor="mail">
							<input placeholder="Email *" name="mail" type="text" minLength="6" maxLength="52" value={mail} onChange={(e) => setMail(e.target.value)} required />
							{mailError && <div className="error">L'email est invalide</div>}
						</label>
						<br />
					</div>

					<label htmlFor="message">
						<textarea name="message" type="text" placeholder="Message *" minLength="12" maxLength="300" value={message} onChange={(e) => setMessage(e.target.value)} required />
						{messageError && <div className="error">Minimum de 12 caractères</div>}
					</label>
					<br />
				</div>
				<input type="submit" value="Envoyer" className="submit" />
				
			</form>
		</div>
	);
};

export default ContactForm;
