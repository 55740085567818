import React, { useState } from "react";
import Brisee from "../books/Brisee";
import arrowLeft from "../../assets/ico/arrow-right-solid.svg"
import Noel from "../books/Noel";

const Books = () => {
	const [booksPageX, setBooksPageX] = useState(false)

	const handleBooksSwipeLeft = () => {
		let booksPage = document.querySelector(".books-section")
		booksPage.scrollLeft += window.innerWidth;
		setBooksPageX(true)
	}
	const handleBooksSwipeRight = () => {
		let booksPage = document.querySelector(".books-section")
		booksPage.scrollLeft -= window.innerWidth;
		setBooksPageX(false)
	}

	return (
		<div className="arrow-container">
			<div className="books-section">
				<div className="books-page">
					<Brisee />
					<Noel />
				</div>
				<img className={booksPageX ? "arrow arrow-left inactif" : "arrow arrow-left"} src={arrowLeft} alt="swipe" onClick={handleBooksSwipeLeft} />
				<img className={booksPageX ? "arrow arrow-right" : "arrow arrow-right inactif"} src={arrowLeft} alt="swipe" onClick={handleBooksSwipeRight} />
			</div>
		</div>
	);
};

export default Books;
