import React from "react";

// img
// import newBook from "../../assets/home/new-book.png";
// import alternate from "../../assets/home/alternate.png";
import introVideo from "../../assets/home/introVideo.mp4"
import preview from "../../assets/home/preview.png"

const Home = () => {
	return (
		<section className="home-section" id="home">
			<div className="home-section__hero">
				{/* <div className="home-section__hero--text">
					<h1>Premier Roman</h1>
					<p>Comment réagiriez-vous si la personne avec qui vous vivez n'est pas celle que vous pensiez ?</p>
					<p>Plongez au cœur d'une histoire entre amour, amitié et faux semblants.</p>
					<a href="#books" className="btn btn-primary">
						Découvrir
					</a>
				</div>

				<div className="home-section__hero--picture">
					<img src={introVideo} alt="new book" />
				</div> */}
				<video src={introVideo} autoPlay={true} loop={true} muted={true} poster={preview} />
			</div>
		</section>
	);
};

export default Home;
