import React from "react";
import ContactForm from "../layouts/ContactForm";

//img
import location from "../../assets/contact/location.svg";
import email from "../../assets/contact/email.svg";

import instagram from "../../assets/contact/instagram.svg";
import tiktok from "../../assets/contact/tiktok.svg";

const Contact = () => {
	return (
		<section className="contact-section" id="contact">
			<div className="contact-section__content">
					<div className="contact-section__content--left-part">
						<div className="item">
							<img src={location} alt="location" />
							<p>Paris</p>
						</div>
						<div className="item">
							<img src={email} alt="email" />
							<a href="mailto:elodiepascal.auteur@gmail.com" target="_blank" rel="noopener noreferrer">
								elodiepascal.auteur@gmail.com
							</a>
						</div>

						<div className="social-links">
							<a href="https://www.instagram.com/elodiepascalauteur" target="blank">
								<img src={instagram} alt="instagram" className="social-link" />
							</a>
							<a href="https://www.tiktok.com/@elodiepascalauteure" target="blank">
								<img src={tiktok} alt="tiktok" className="social-link" />
							</a>
						</div>
					</div>
					<ContactForm />
				</div>
		</section>
	);
};

export default Contact;
