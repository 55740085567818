import React from "react";
import logo from "../assets/author/logo.png"

import user from "../assets/ico/user-solid.svg"
import book from "../assets/ico/book-solid.svg"
import service from "../assets/ico/pen-fancy-solid.svg"
import message from "../assets/ico/message-solid.svg"

const NavBar = () => {
	return (
		<nav className="navbar-container">
			<a href="#home">
				<div className="navbar-container__logo">
					<img src={logo} alt="Elodie Pascal logo" />
				</div>
				{/* <h3 className="navbar-container__title">Elodie Pascal</h3> */}
			</a>
			<ul className="navbar-container__links desktop">
				<li className="link">
					<a href="#author">L'autrice</a>
				</li>
				<li className="link">
					<a href="#books">Livres</a>
				</li>
				<li className="link">
					<a href="#services">Services</a>
				</li>
				<li className="link">
					<a href="#contact">Contact</a>
				</li>
			</ul>
			<ul className="navbar-container__links mobile">
				<li className="link">
					<a href="#author">
						<img src={user} alt="Présentation de l'autrice" />
					</a>
				</li>
				<li className="link">
					<a href="#books">
						<img src={book} alt="Les livres d'Elodie" />
					</a>
				</li>
				<li className="link">
					<a href="#services">
						<img src={service} alt="Les services proposés" />
					</a>
				</li>
				<li className="link">
					<a href="#contact">
						<img src={message} alt="Prendre contact" />
					</a>
				</li>
			</ul>
		</nav>
	);
};

export default NavBar;
