import React from "react";

// img


const Services = () => {
	return (
		<section className="services-section" id="services">
			<div className="services-section__content">
				<h2>Services</h2>
                <h3 className="principal-service">Bêta-lecture</h3>
                <p>
                    Vous souhaitez recevoir un retour professionnel et objectif sur votre manuscrit ?
                    Je vous propose deux types de <strong>bêta-lecture</strong> : simple et avancée.
                </p>
                <br/>
                <p>
                    Je m’adapte à vos <strong>besoins</strong>, que ce soit pour la bêta-lecture de votre manuscrit dans sa globalité, ou
                    d’un passage précis. S’il y a d’ores et déjà des points sur lesquels vous souhaitez que je travaille en
                    particulier, je vous laisse me le préciser.
                </p>
                <br/>

                <p>
                    En fonction des réservations, je peux également m’adapter à vos délais (si vous prévoyiez par
                    exemple une publication en <strong>autoédition</strong> ou une soumission à un <strong>éditeur</strong>).
                </p>
                <br/>
                <p>
                    Je ne travaille pas sur les manuscrits suivants : non-fiction, poésie, théâtre, textes de moins de 40 000
                    mots.
                </p>
                <br/>
                <p>
                    Les devis sont envoyés après un échange approfondi avec vous : vos souhaits/attentes, votre roman,
                    l’accompagnement souhaité.
                </p>

                
                <h3>Bêta-lecture simple</h3>
                <p>
                    Bêta-lecture de votre roman dans sa globalité.
                    J’analyse les points forts, les points faibles, les personnages, les intrigues, la cohérence, le style soit
                    un retour complet sur votre manuscrit.
                    Un compte-rendu <strong>global et rubriqué</strong> vous est rendu.
                </p>
                <br/>
                <div className="tarif">
                    <p><strong>Tarif </strong>: 0,20€ les 100 mots</p>
                </div>
                <h3>Bêta-lecture avancée</h3>
                <p>
                    En plus du compte-rendu global et rubriqué, j’annote directement votre texte pour vous proposer un
                    retour détaillé, ligne par ligne, de votre roman.
                </p>
                <br/>
                <div className="tarif">
                    <p><strong>Tarif </strong>: 0,50€ les 100 mots</p>
                </div>
                <br/>
                <br/>

                <p>
                    Discutons de votre projet, et/ou demandez-moi des précisions via le formulaire ci-dessous ou à
                    l’adresse suivante : <a href="mailto:elodiepascal.auteur@gmail.com" target="_blank" rel="noopener noreferrer">elodiepascal.auteur@gmail.com</a>.
                </p>
			</div>
		</section>
	);
};

export default Services;
